.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ebebeb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.buttons{
  align-items: center;
  justify-content: center;
}


.textohead{
  color: white;
}
.titles{
  text-align: center;
}
.body_text{
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.accordion-body-custom {
  min-height: 150px;
  max-height: 150px;
  overflow-y: auto;
  padding: 1rem;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.file-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px; 
}

input[type="file"] {
  width: 100%;
  max-width: 100%; 
}


